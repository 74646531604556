import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includes'
})
export class IncludesPipe implements PipeTransform {
  transform(value: string | Array<string>, includeString?: string): boolean {
    if(!(value && includeString)) return false;

    return value.includes(includeString)
  }
}
