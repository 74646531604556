import { Injectable } from '@angular/core';
import testingVariables from './testing.json'
import { Unsubscribe, User, onAuthStateChanged, signOut, GoogleAuthProvider, signInWithRedirect, connectAuthEmulator, getAuth } from 'firebase/auth';
import { BehaviorSubject, filter, first } from 'rxjs';
import { Router } from '@angular/router';


@Injectable()
export class AuthService {

    testing = testingVariables.testing

    auth = getAuth()

    unsubscribe: Unsubscribe

    public userSubject: BehaviorSubject<User> =  new BehaviorSubject(undefined)

    constructor(
        private router: Router
    ) {
        if(this.testing) connectAuthEmulator(this.auth, "http://localhost:9099", {disableWarnings: true})

        this.unsubscribe = onAuthStateChanged(this.auth, user => {
            //" ?? null" just ensures it is null if fetched but no value
            this.userSubject.next(user ?? null)
            if(!user) {
                this.router.navigate(["/login"])
            }
        })
    }

    /**
     * Gets the currently logged in user.
     * 
     * @returns A promise that only resolves when a non-nullish value is received for the user.
     */
    getUser() {
        return new Promise<User>((resolve) => {
            this.userSubject.pipe(filter(user => !!user), first()).subscribe(async user => resolve(user) )
        })
    }


    async signInWithGoogle() {
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({hd: "dorado.com.au"})
        await signInWithRedirect(this.auth, provider)
    }


    signOut(): Promise<void> {
        return signOut(this.auth)
    }

    

}
