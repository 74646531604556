import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that returns the index of the first true value it finds, or -1 if none
 * 
 * For this to work it requires the array to be reassigned when modified
 */
@Pipe({
  name: 'trueIndex'
})
export class TrueIndexPipe implements PipeTransform {
  transform(value: Array<boolean>): number {
    if(!(value)) return -1;

    return value.findIndex(x => x)
  }
}
