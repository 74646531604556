import { Injectable } from '@angular/core';
import {  getStorage, connectStorageEmulator, getDownloadURL, ref, uploadBytes, uploadString } from 'firebase/storage';
import testingVariables from './testing.json'



@Injectable(
    { providedIn: 'root' }
)
export class StorageService {

  public storage = getStorage()


  termSheetDocRef = ref(this.storage, 'templates/loop-table-2.docx')

  constructor(
  ) {
    if(testingVariables.testing) connectStorageEmulator(this.storage, "localhost", 9199)
  }
  

  async getTermSheetDoc() {
    const url = await getDownloadURL(this.termSheetDocRef);
    return await fetch(url).then(r => r.arrayBuffer())
  }

  uploadTermSheetDoc(file: File) {
    return uploadBytes(this.termSheetDocRef, file)
  }

}
