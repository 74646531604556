import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { Platform } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';
import { GlobalsService } from 'src/services/globals.service';
import { Location } from '@angular/common';
import { UploadService } from 'src/services/upload.service';
import { StorageService } from 'src/services/storage.service';
import saveAs from 'file-saver';
import { UIService } from 'src/services/ui.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    public auth: AuthService,
    public router: Router,
    public globals: GlobalsService,
    public location: Location,
    public upload: UploadService,
    private storage: StorageService,
    private ui: UIService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {

    });
  }

  async downloadTermSheet() {
    const termSheetDoc = await this.storage.getTermSheetDoc()
    saveAs(new Blob([termSheetDoc]), "Term Sheet Template.docx")
  }

  async warnUpload(f: HTMLInputElement) {
    await this.ui.presentOKAlert("Dangerous Operation", "By providing a new template you risk breaking the term sheet generation. <b>Make sure you have saved a backup of the working term sheet template.</b>")
    f.click()
  }

  termSheetTemplate: string

  async uploadTermSheet(event: Event) {
    const loading = await this.ui.presentLoading('Uploading...')

    const target = event.target as HTMLInputElement
    const file = target.files[0]
    this.termSheetTemplate = undefined
    await this.storage.uploadTermSheetDoc(file)

    await loading.dismiss()
  }

  signout() {
    return this.auth.signOut()
  }

}
