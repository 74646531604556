import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncludesPipe } from './includes.pipe';
import { TrueIndexPipe } from './true-index.pipe';
import { AllTruePipe } from './all-true.pipe';



@NgModule({
  declarations: [
    IncludesPipe,
    TrueIndexPipe,
    AllTruePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IncludesPipe,
    TrueIndexPipe,
    AllTruePipe
  ],
  providers: [
    IncludesPipe,
    TrueIndexPipe,
    AllTruePipe
  ]
})
export class PipesModule { }
