import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';


@Injectable(
    { providedIn: 'root' }
)
export class UIService {

    constructor(
        private alertController: AlertController,
        private loadingCtrl: LoadingController
    ) {}

    presentOKAlert(header: string, message: string) {
        return new Promise<void>(async (resolve) => {
        const alert = await this.alertController.create({
            header,
            message,
            buttons: [
              {
                text: 'Okay',
                role: 'cancel',
                handler: resolve
              }
            ]
          });
          await alert.present();
        })
    }

    async presentLoading(message: string) {
      const loading = await this.loadingCtrl.create({ message });

      await loading.present();

      return loading
    }
    

}
