export class ClausesObject {
    clauses: Array<Clause>

    constructor(_clauses: FirebaseClauses) {
        const clauses = Object.entries(_clauses)
        this.clauses = clauses.map(([header, clause]) => new Clause({header, ...clause}) ).sort((a,b) => (a.order - b.order))
    }

    
}

/**
 * The Clauses object as it is stored in Firebase
 */
export class FirebaseClauses {
    [header: string]: FirebaseClause
}

/**
 * The Clause object as stored in Firebase
 */
export class FirebaseClause {
    order: number
    content: Array<Content>
}

export class Clause extends FirebaseClause {
    header: string

    constructor(clause: Clause) {
        super()
        this.header = clause.header
        this.order = clause.order
        this.content = clause.content.map(content => new Content(content))
    }

    /**
     * Interprets the plain text elements in the clause to create a split clause
     */
     splitClause?(forFill = false) {
        const header = this.header
        const content = this.content.map(content => new SplitContent(undefined, content, forFill))

        return new SplitClause({
            header,
            content,
            order: this.order,
            include: true
        })
    }

    copy?() {
        return new Clause({
            header: this.header,
            order: this.order,
            content: this.content.map(content => {
                return new Content({
                    text: content.text.map(text => text),
                    type: content.type,
                    ticked: content.ticked.map(ticked => ticked)
                })
            })
        })
    }

    toFirebase?(): [string, FirebaseClause] {
        return [this.header, {
            order: this.order,
            content: this.content.map(content => {
                //ensures the ticked parameter is always false
                content.ticked = content.ticked.map(ticked => false)
                return content
            }),
        }]
    }

    deleteContent?(index: number) {
        this.content.splice(index, 1)
    }
}

export type ContentType = "inline" | "list" | "options"

export class Content {
    text: string[] = ["New Text"]
    type: ContentType = "inline"
    ticked: Array<boolean> = [false]

    constructor(content: Partial<Content>) {
        Object.assign(this, content)
    }

    addText() {
        this.text.push("New Text")
        this.ticked.push(false)
    }

    /**
     * Removes the text at the provided index
     * 
     * @param index the index of the text to remove
     * @returns whether the length of the text is 0
     */
    removeText(index: number) {
        this.text.splice(index, 1)
        this.ticked.splice(index, 1)
        return this.text.length == 0
    }
}

export class SplitClause {
    order: number
    header: string
    content: Array<SplitContent>
    include = true
    
    constructor(clause: SplitClause) {
        Object.assign(this, clause)
    }

    /**
     * Makes a deep copy of the split clause
     * 
     * @returns a deep copy of the split clause
     */
    copy?() {
        return new SplitClause({
            order: this.order,
            header: this.header,
            include: this.include,
            content: this.content.map(content => {
                return new SplitContent({
                    text: content.text.map(text => text.map(subText => subText)),
                    optChecked: content.optChecked.map(optChecked => optChecked.map(subOptChecked => subOptChecked)),
                    type: content.type,
                    ticked: content.ticked.map(ticked => ticked),
                })
            })
        })
    }

    /**
     * Unticks all the content's ticked data
     */
    untick?() {
        this.content = this.content.map(content => {
            content.ticked = content.ticked.map(x => false)
            return content
        })
    }
}

export const KeyTerms = ["text", "opt", "/opt"]

export class SplitContent {
    /**
     * Stores the split text
     */
    text: string[][] = []
    /**
     * Stores boolean indicating whether the text has been checked using [opt][/opt] syntax
     */
    optChecked: boolean[][] = []
    type: ContentType = "inline"
    ticked: Array<boolean> = []

    constructor(splitContent?: SplitContent, content?: Content, forFill = false) {
        if(splitContent) return Object.assign(this, splitContent)
        if(!content) return
        const splitText = content.text.map(text => text.split(new RegExp('\\[|\\]')))
        this.text = splitText.map(text => text.map(subText => forFill && KeyTerms.includes(subText) ? "" : subText))
        let inOpt = false
        this.optChecked = splitText.map(text => text.map(subText => {
            if(subText == 'opt') inOpt = true
            if(subText == '/opt') inOpt = false
            return !inOpt
        }))
        this.type = content.type
        this.ticked = content.ticked
    }

    /**
     * A function that is only applicable to "option" input type. Handles a change to the radio group
     * @param index 
     */
    changeOption?(index: number) {
        this.ticked = this.ticked.map((val, i) => index === i)
    }

    tick?(index?: number) {
        if(index === undefined) {
            const isAllTicked = this.ticked.filter(x => x === false).length === 0
            this.ticked = this.ticked.map(val => !isAllTicked)
            return
        }
        this.ticked = this.ticked.map((val, i) => i === index ? !val : val)
    }
}