import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable(
    { providedIn: 'root' }
)
export class GlobalsService {

    constructor(
        public router: Router
    ) {}

    termSheetPage = "term-sheet"

    termSheetName = undefined
    

}
