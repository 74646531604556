import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that returns whether all of the elements in an array are true
 * 
 * For this to work, the array will need to be reassigned when it is modified
 */
@Pipe({
  name: 'allTrue'
})
export class AllTruePipe implements PipeTransform {
  transform(value: Array<boolean>): boolean {
    if(!(value)) return false;

    return value.filter(x => x === false).length === 0
  }
}
